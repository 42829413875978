.main-solplanet-container{
    background: url(../../images/invertervision.webp) no-repeat;
    background-attachment: fixed;
    filter: brightness(0.9);
    overflow: hidden;
    background-size: cover;
   
    
}



.vision-container{
    padding: 30px;
    position: relative;
    background-color: #fff;
   
}

.vision-dott{
    position: absolute;
    left: 0;
    top: 10%;
    z-index: 0;
}



.acc-head{
    background-color: #f4f0f0;
  
}

.acc-btn:not(.collapsed){
    box-shadow: none !important;
} 

.vision-align{
  margin: auto 0px;
}
.acc-item{
    border: none !important;

}

.vistion-title h3{
    font-size: 35px;
    color: #5d4949;
    font-weight:500 ;
}

.right-vision-container{
    height: 90vh;
    width: 30%;
}
.right-vision-container img{
    object-fit: cover;
    height: 100%;
    z-index: 1;
    position: relative;
   
}

.solplanet-cards{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-context{
   
    height: 150px;
    border-radius: 5px;
    background-color: floralwhite;
    position: relative;
}

.align-card{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    flex-direction: column;
}

.align-card p:nth-child(2){
    text-align: center;
}

.card-context{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

/* parallax image */
.solplanet-parallax{
        background: url(../../images/shaddesolparallax.webp);
        background-position: 30% ;
        background-attachment: fixed;
        background-size: cover;
        height: 100vh;
}
.readvalue{
    background: transparent;
    border: none;
    color: white !important;
    font-size: 17px;

}
.message-con{
    opacity: 0 !important;
    height: 1px;
}


.accordion-button:focus{
    box-shadow: none !important;
 
} 

.right-vison-sec img{
    height: 30vh;
    width: 100%;
}
.acc-btn{
    font-weight: 600;
    font-family: system-ui;
    letter-spacing: 1.1px;
    padding: 10px 0px !important;
}



.first-timeline{
    padding: 25px;
    border-top:7px solid #084772 ;
    border-right: 7px solid #084772;
    border-radius: 0px 20px;
    position: relative;
    right: 0px;
  

 
}

.second-timeline{
    padding: 25px;
    border-top:7px solid #084772 ;
    border-left: 7px solid #084772;
    border-radius: 20px 0px 0px 0px;
    position: relative;
    float: right;
    left:-7px;


}
.timeline{
    min-height: 100vh;
}
.subtitle-con{
    position: relative;
}
/* .stateline{
    position: absolute;
    right: -6px;
    height:700px;
    background-color: #084772;
    border-radius: 20px;
    width: 7px;
} */

.first-timeline-cont p{
    text-align: center;
}

.first-timeline-cont{
    background-color: #E84C47;
    position: relative;
    color: white !important;
    font-size: 18px;
    margin: 10px 30px;
    border-radius:20px;
    letter-spacing: 1.2px;
    padding:  10px;
}
.timeline-round{
    position: relative;
}
.year{
    position: absolute;
    transform: translate(-50%,50%);
    left: 50%;
    top: -80%;
    font-size: 13px;
    color: white !important;
   
}
.timeline-round-second{
    position: relative;
}
.year-second{
    position: absolute;
    transform: translate(-50%,50%);
    left: 50%;
    top: -80%;
    font-size: 13px;
    color: white !important;
   
}
.second-timeline-cont{
    background-color: darkorange ;
    position: relative;
    color: #fff;
    font-size: 18px;
    margin: 10px 30px;
    border-radius:15px;
    letter-spacing: 1.2px;
    padding:  10px;
}
.first-timeline-cont :nth-child(2){
    font-size: 15px;
}

.second-timeline-cont :nth-child(2){
    font-size: 15px;
}

.second-timeline-cont p{
    text-align: center;
}

.timeline-round{
    width: 50px;
    position: absolute;
    top: -28px;
   left: -20px;
    height: 50px;
    background-color: #E84C47;
    border-radius: 50%;
    border: 1px solid #084772;
}
.timeline-round-second{
    width: 50px;
    position: absolute;
    top: -28px;
    right: -20px;
    height: 50px;
    background-color: darkorange;
    border-radius: 50%;
    border: 1px solid #084772;
}

.line-one{
    height: 40px;
    position: absolute;
    top: -5px;
    left: 130px;
    width: 5px;
    background-color: #E84C47;
    border-radius: 20px;
}
.line-two{
    height: 40px;
    position: absolute;
    top: -5px;
    right: 140px;
    width: 5px;
    background-color: #E84C47;
    border-radius: 20px;
}


.second-line-one{
    height: 40px;
    position: absolute;
    top: -5px;
    left: 130px;
    width: 5px;
    background-color: darkorange;
    border-radius: 20px;

}

.second-line-two{
    height: 40px;
    position: absolute;
    top: -5px;
    right: 140px;
    width: 5px;
    background-color: darkorange;
    border-radius: 20px;
}
.first-timeline-cont,.second-timeline-cont{
    margin: 0px;
}
.first-timeline-cont > p{
    color: white !important;
}


.timeline{
    position: relative;
}

.timeline::before{
    background: url(../../images//fashion1.webp) left top no-repeat, url(../../images//fashion3.webp) left top no-repeat;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-size: 70%,40%;
    width:600px;
    height: 1300px;
}

.timeline::after{
    background: url(../../images//fashion1.webp) right top no-repeat, url(../../images/fashion2.webp) right top no-repeat;
    content: "";
    width: 600px;
    height:1300px ;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0 !important;
    background-size: 70% , 40%;
   
}


@media screen and (max-width:1180px) {
    .stateline{
        display: none;
    }.timeline-round,.timeline-round-second{
        width: 40px;
        height: 40px;
    }
    .first-timeline-cont,.second-timeline-cont{
        margin-left: 0px;
        margin-right: 0px;
        font-size: 18px;
    }
    .line-one,.second-line-one{
        left: 70px;
    }
    .line-two,.second-line-two{
        right: 70px;
    }
    .year,.year-second{
        top: -110%;
    }
    
    
}

@media screen and (max-width:1150px) {
    .right-vision-container{
        width: 100%;
        padding: 0px;
    }
    .solplanet-parallax {
        display: none;
    }
    #tit-head{
        padding: 10px;
    }
    .planet-div{
        padding: 20px;
    }
    .inv h2{
        padding:  10px;
    }
   
}