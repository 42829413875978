.faq-setion{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accordion-button{
    width: 100%;
    text-align: start;
    color: black;
    font-weight: 500;
    background-color: white;
    padding: 20px 0px;
    margin: 0px;
    border: none;
   
    font-size: 16px;
}
.faq-icon i{
    font-size: 18px;
   
}
.acc-btn,.acc-bdy{
    text-transform: capitalize;
}
.accordion-flush{
    margin-top: 50px;
}
.fap-inner{
    z-index: 2;
}
.accordion-button:active,.accordion-button:focus{
    border: none;
    outline: none;
}

.accordion-button{
    background-color: #FFFCF3;
    font-size: 18px;
    font-weight: 600;
}
.accordion-header{
    margin-bottom:5px;


}

.faptitle {
    font-size: 27px;
    font-weight: 600;
    text-transform: capitalize;
  
}

.faq-context{
    padding: 20px;
    margin: 30px 0px;
}

.accordion-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button:focus {
    outline: none;
  }