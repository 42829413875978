.x-cross{
 display: flex;
 justify-content: flex-end;
 align-items: center;
 margin: 20px;
 color: rgb(68, 60, 60);
}


.modal-btn i{
    font-size: 22px;   
     display: flex;
    justify-content: end;


}

.modal-form img{
        height: 250px;
        width: 100%;
        object-fit:fill;

}
.modal-form{
    position: relative;
    z-index: 10;
}


.modal-field{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-field input{
    width: 90%;
    margin: 5px 0px;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid rgb(201, 185, 185);
}


@media screen and (max-width:1150px) {
    .ReactModal__Content{
        width: 80%;
     
    }.modal-form img{
        object-fit: contain ;
    }

    
}